import mainApp from '../funciones/HelperMainWrapper';
import { app } from '../funciones/HelperMainWrapper';
import Swal from 'sweetalert2/dist/sweetalert2.js';
//import { App as Application, Component } from "vue";
//const app = HelperMainWrapper.app;


export enum MessageType {
    Info = 1,
    Error,
    Aviso,
    Correcto,
    Pregunta,
}

//const $cookies = VueCookies(),

/**
 * @description get value cookie
 */
export const showMessage = (
    tipo: MessageType,
    title: string,
    text: string,
    showConfirmButton = true,
    showCancelButton = false,
    allowOutsideClick = false,
    imageUrl = '',
    callbackOk: any = undefined,
    callbackCancel: any = undefined,
    callBackOkTimeOut = -1,
    callBackCancelTimeOut = -1,
    confirmButtonText = 'Aceptar',
    cancelButtonText = 'Cancelar',
    showSiButton = false,
    showNoButton = false,
    callbackSi: any = undefined,
    callbackNo: any = undefined,
): void => {
    Swal.fire({
        customClass: {
            container: 'custom-swal',
        },
        icon: getType(tipo),
        title: title,
        html: text,
        confirmButtonText: showSiButton ? 'Sí' : confirmButtonText,
        cancelButtonText: showNoButton ? 'No' : cancelButtonText,
        imageUrl: imageUrl,
        showConfirmButton: showConfirmButton || showSiButton,
        showCloseButton: false,
        showCancelButton: showCancelButton || showNoButton,
        allowOutsideClick: allowOutsideClick,
        showClass: {
            popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
        },
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            if (showSiButton && callbackSi) {
                callbackSi();
            } else if (callbackOk) {
                callbackOk();
            }
        }
        else {
            if (showNoButton && callbackNo) {
                callbackNo();
            } else if (callbackCancel) {
                callbackCancel();
            }
        }
    });

    if (callbackOk && callBackOkTimeOut && callBackOkTimeOut !== -1) {
        setTimeout(() => {
            callbackOk()
        }, callBackOkTimeOut);
    }

    if (callbackCancel && callBackCancelTimeOut && callBackCancelTimeOut !== -1) {
        setTimeout(() => {
            callbackCancel()
        }, callBackCancelTimeOut);
    }
};

const getType = (tipo: MessageType) => {
    if (tipo == MessageType.Error) return 'error';
    if (tipo == MessageType.Aviso) return 'warning';
    if (tipo == MessageType.Info) return 'info';
    if (tipo == MessageType.Correcto) return 'success';
    if (tipo == MessageType.Pregunta) return 'question';

    return 'info';
};

const getSeverity = (tipo: MessageType) => {
    if (tipo == MessageType.Error) return 'error';
    if (tipo == MessageType.Aviso) return 'warn';
    if (tipo == MessageType.Info) return 'info';
    if (tipo == MessageType.Correcto) return 'success';

    return 'info';
};

export const showToast = (tipo: MessageType, title: string, text: string, life: number = 3000, position: string = 'bottom-right'): void => {
    const _tipo = getSeverity(tipo);
    app.config.globalProperties.$toast.add({ severity: _tipo, summary: title, detail: text, life: life, position: position } as any);
};

export const hideAllMessages = (): void => {
    Swal.close();
};

export default { MessageType, showMessage, showToast, hideAllMessages };
